.popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    background-image: radial-gradient(#5e5c5c,rgb(181, 178, 178));
    padding: 20px;
    /* border: 1px solid #000; */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 40px;
    z-index: 30;
    display: flex;
    align-items: center;
}
.popup-content {
    text-align: center;
}
.text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}
.head{
    display: flex;
    
    
}

.btn{
    background-color: #000;
    color: white;
    margin-top: 10px;
}
h1{
    font-size: 30px;
    font-weight: bold;
    
}
.para{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 40px;
}


.rate{
    color: rgb(255, 255, 255);
    margin-left: 22px;
    font-weight: bold;
}
@media screen and (max-width:480px){
    .popup{
        padding: 10px;
        width: 300px;
    }
    .popup-content{
        margin-left: 30px;;
    }
    .para{
       padding-right: 20px;
    }
}