.main-top1{
    display: flex;
    gap: 20px; 
    padding-left: 13px; 
     
}
.main-top2{
    padding-right: 13px; 
    display: flex;
    gap: 15px; 
    margin-left: 40px;
    align-items: center;
}
.nav-link{
  text-decoration: none;
  color: black;
}
.top-mail{
  text-decoration: none;
  color: white;
}
.logo{
  width: 12%;
  height: 12%;
}
.insta{
  text-decoration: none;
  color: rgb(184, 183, 183);
  margin-top: 0;
}
.topbar{
    background-color: rgb(0, 0, 0);
    z-index: 30;
    position: relative;
    color: rgb(199, 199, 199);
    font-size: 12px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.ic{
    font-size: 14px;
    margin-right: 5px;
}
.ic1{
    font-size: 15px;
}

.nav {
    background-color: white;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    z-index: 20;
    position: relative;

  }
 img{
    width: 110px;
    height: 110px;
    margin-left: 40px;
 }
.icon{
    width: 20px;
    height: 20px;
}
  
  .navigation {
    display: flex;
    gap: 60px; 
    padding-right: 50px;
    cursor: pointer;

  }
  
  .ham {
    display: none;
    border: none;
    padding-right: 27px;
    background-color:transparent;
    
  }
  .icon{
    font-size: 25px;
    background-color: #ffffff;
  }
  .drop2{
    
    font-size: 16px;
    
  }
  .content{
    padding: 15px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    text-align: center;
  }
  
  .drop {
    background-color: #9e9e9e;
    position: absolute;
    width: 100%;
    height: 280px;
    color: white;
    transition: all 0.2ms;
    z-index: 40;
    display: none;
  }
  
  
  .drop.active {

    display: block;
  }
    @media only screen and (max-width: 769px) {
      .ham {
        display: block;
      }
    
      .navigation {
        display: none;
      }
      .nav{
        padding: 0;
      }
      .logo{
        width: 10%;
        height: 10%;
      }
    }
  
  @media only screen and (max-width: 425px){
   
    .nav{
      padding: 0;
    }
    .main-top1{
        font-size: 10px;
   }
   .logo{
    width: 15%;
    height: 15%;
  }
}
  @media only screen and (max-width: 375px){
   
    .nav{
      padding: 0;
    }
    .main-top1{
        font-size: 13px;
   }
   .main-top2{
    display: none;
   }
   .logo{
    width: 15%;
    height: 15%;
  }
}
  