.feature-contain{
    margin-top: 80px;
}
.f-col{
    /* border: 2px solid black; */
    margin-left: 40px;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #ccc ;
    background-color: rgba(176, 228, 249, 0.678);
}
.f-icon{
    font-size: 35px;
    margin-bottom: 10px;
}
.f-head{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
    color: rgb(203, 58, 5);
}
.f-para{
    font-size: 13px;
    margin-bottom: 10px;
}
@media only screen and (max-width:900px) {
    .f-col{
        margin-left: 10px;
    }
    
}
@media only screen and (max-width:500px) {
    .f-col{
        margin-left: 1px;
    }
    
}