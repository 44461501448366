.land-row{
    /* border: 1px solid black; */
    margin: 0 auto;
}
.column1 {
    position: relative;
    /* border: 1px solid black; */
 }
.column1::before {
    content: "";
    position: absolute;
    top: 80px;
    left: 130px;
    width: 60%;
    height: 70%;
    background-image: url(./Images/mechanic-153736_1280.png);
    background-size: 100% 100%;
    opacity: 0.1; /* Adjust the opacity as needed */
 }
.land-head{
    font-size: 80px;
    font-weight: bold;
    /* text-align: justify; */
    margin-bottom: 20px;
}
.land-span{
    color: rgb(205, 62, 10);
}
.land-para{
    font-size: 18px;
    color: grey;
    text-align: justify;
    margin-bottom: 20px;
}
.land-btn{
    padding: 10px 20px;
    background-color: rgb(9, 9, 9);
    border: none;
    border-radius: 2px;
    color: white;
}
.land-btn a{
    color: white;
    text-decoration: none;
}
.enquire-btn{
    margin-left: 20px;
}
.column2{
    /* border: 1px solid red; */
}
.land-img{
    width: 100%;
    height: 100%;
}
@media only screen and (max-width:800px) {
    .column1{
        text-align: center;
    }
    .land-head{
        font-size: 60px;
    }
    .column1::before{
        top: 50px;
        left: 260px;
        width: 30%;
        height: 75%;
    }
    .land-img{
        width: 80%;
        
    }
}
@media only screen and (max-width:576px) {
    .column1{
        text-align: center;
    }
    .land-head{
        font-size: 45px;
    }
    .column1::before{
        top: 60px;
        left: 110px;
        width: 45%;
        height: 55%;
    }
}
