
  @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

*{
    padding: 0;
    margin: 0;
    font-family: 'Oswald', sans-serif;

}

.service-land{
    width: 100%;
    height: 80vh;
    background-image: url(./Images/WhatsApp\ Image\ 2024-02-02\ at\ 13.12.15_93807aab.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
}
.service-head{
    color: rgba(223, 71, 1, 0.892);
    font-size: 110px;
    font-family: 'Oswald', sans-serif;
    /* border: 1px solid red; */
    padding-top: 200px;
   opacity: 1;
}
.service-para{
    font-family: 'Oswald', sans-serif;
    /* border: 1px solid red; */
    color: white;
    font-size: 25px;


}
.plan-head{
    font-size: 50px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.plan-head-clr{
    color: rgba(223, 71, 1, 0.892);
}
.plan-col{
    margin-left: 20px;
    box-shadow: 2px 2px 10px #212121;
    border-radius: 10px;
    /* border: 1px solid black; */
    /* margin-top: 40px; */
}
.plan-in-head{
    font-size: 25px;
    font-weight: 700;
    margin-top: 20px;
    /* color: rgba(255, 68, 0, 0.816); */
}
.plan-in-para{
    font-size: 16px;
    text-align: center;
    /* border: 1px solid red; */
}
.line{
    width: 150px;
    border-bottom: 2px solid rgb(249, 83, 0);
    margin:0 auto;
}
.icon-curr{
    font-size: 12px;
}
.span-month{
    font-size: 10px;
}
.plan-price{
    margin-top: 10px;
    font-size: 35px;
}
.plan-feature{
    /* border: 1px solid black; */
    /* width: 300px; */
    margin-top: 30px;
}
.plan-feature p{
    float: left;
    padding-left: 10px;
}
.icon-tool{
  padding-right: 10px;
  color: rgb(253, 0, 0);
  font-size: 18px;
}
.icon-tool-1{
  padding-right: 10px;
  /* color: rgb(253, 0, 0); */
  font-size: 18px;
}
.plan-btn{
    padding: 5px 8px;
    border: none;
    border-radius:5px;
    background-color: black;
    color: white;
    margin-bottom: 20px;
}
.plan-btn:hover{
    background-color:  rgba(223, 71, 1, 0.892);
}
@media screen and (max-width:500px){
    .service-land{
        height: 60vh;
    }
    .service-head{
        padding-top: 160px;
        font-size: 60px;
    }
    .service-para{
        font-size: 16px;
    }
}