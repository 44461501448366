.abt-contain{
    margin-top: 60px;
}
.aboutimg1{
    /* margin-top: 20px; */
      width: 100%;
      height: 100%;
}
.abt-row{
    /* border: 1px solid black; */
}
.abt-img2{
    width: 40%;
    height: 20%;
    margin-left: 100px;
}
.abt-head{
    font-size: 35px;
    font-weight: 700;
    margin-left: 170px;
    position: relative;
    bottom: 35px;
    color: rgb(200, 56, 4);
    /* left: 110px; */
}
.abt-para{
    text-align: justify;
    font-size: 18px;
}
.abt-list{
    font-size: 18px;
    line-height: 2;
    font-weight: 600;
}

@media only screen and (max-width:1025px) {
    
    .abt-img2{
        margin-left: 160px;
        width: 40%;
    }
    .abt-head{
        margin-left: 200px;
        font-size: 28px;
    }
   
}
@media only screen and (max-width:800px) {
    
    .abt-img2{
        margin-left: 240px;
        width: 28%;
    }
    .abt-head{
        margin-left: 285px;
    }
    .abt-col1{
        margin-top: 30px;
    }
    .aboutimg1{
        /* border: 1px solid black; */
        margin-left: 0;
    }
   
}
@media only screen and (max-width:500px) {
    
    .abt-img2{
        margin-left: 120px;
        width: 35%;
    }
    .abt-head{
        margin-left: 140px;
        font-size: 21px;
    }
}
@media only screen and (max-width:376px) {
    
    .abt-img2{
        margin-left: 100px;
    }
    .abt-head{
        font-size: 20px;
        margin-left: 124px;
    }
    .abt-col1{
        margin-top: 20px;
    }
}
@media screen and (max-width:321px) {
    .abt-head{
        margin-left: 110px;
    }
    
}