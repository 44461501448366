.whyus-head-img{
    width: 5%;
    margin-left: 10px;
}
.whyus-head{
    font-size: 40px;
    font-weight: bolder;
}
.whyus-para{
    text-align: justify;
}
.whyus-col2{
    margin-left: 20px;
}
.whyus-img2{
    width: 100%;
    height: 400px;
}
.percentage-img{
    margin-top: 40px;
    width: 85%;
}
@media only screen and (max-width:1025px){
    .whyus-col2{
        margin-top: 40px;
    }
    .percentage-img{

        width: 80%;
    }
    .whyus-img2{
        margin-left: 0;
    }
    .whyus-col2{
        margin-left: 0;
    }
    .whyus-para{
        margin-top: 20px;
    }
    .whyus-head-img{
        width: 7%;
    }
}
@media only screen and (max-width:800px){
    .whyus-col2{
        margin-top: 40px;
    }
    .whyus-col2{
        margin-left: 0;
    }
    .whyus-para{
        margin-top: 20px;
    }
    .whyus-head-img{
        width: 7%;
    }
}
@media only screen and (max-width:500px){
    .whyus-col2{
        margin-top: 40px;
    }
    .whyus-col2{
        margin-left: 0;
    }
    .whyus-para{
        margin-top: 20px;
    }
    .whyus-head-img{
        width: 10%;
        height: 10%;
    }
}