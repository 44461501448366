.foot-contain{
    background-color: black;
    color: white;
    padding: 10px;
}
.foot-content{
    width: 85%;
    margin: 0 auto;

}

.foot-logo{
    width: 50%;
    height: 50%;
    /* border: 1px solid white; */
    margin-left: 0;
}
.foot-para{
    text-align: justify;
    margin-top: 20px;
}
.sm-icon{
    font-size: 30px;
    margin-left: 20px;
}
.foot-col2{
    margin-left: 90px;
    margin-top: 70px;
}
.foot-col2 h6{
    font-weight: 500;
    color: rgb(205, 62, 11);
    
}
.foot-col2 li{
    list-style: none;
    line-height: 2;
}
.foot-col2 li a{
    text-decoration: none;
    color: white;
}

.foot-col3{
    margin-top: 70px;
}
.foot-col3 h6{
    font-weight: 500;
    color: rgb(205, 62, 11);

}
.footdet{
    font-size: 15px;
}

.copyright {
    text-align: center;
    margin-top: 30px;
    color: coral;
}
.kriya a{
    text-decoration: none;
    color: rgba(229, 181, 5, 0.94);
}
@media only screen and (max-width:800px) {
    .foot-col2{
        margin-left: 0;
    }
}
@media only screen and (max-width:500px) {
    .foot-col2{
        margin-left: 0;
    }
    .sm-icon{
        font-size: 20px;
    }
}