
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
    max-width: 300px;
    overflow: hidden;
  }
  
  .stars {
    display: flex;
    grid-gap: 0.125rem;
    gap: 0.125rem;
    color: rgb(238, 203, 8);
  }
  
  .star {
    height: 1.25rem;
    width: 1.25rem;
  }
  
  .infos {
    margin-top: 1rem;
  }
  
  .date-time {
    color: rgba(7, 63, 216, 1);
    font-size: 12px;
    font-weight: 600;
  }
  
  .description {
    margin-top: 0.4rem;
    line-height: 1.625;
    color: rgba(107, 114, 128, 1);
  }
  
  .author {
    margin-top: 1.3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(0, 0, 0);
  }
  .test-head{
       font-size: 40px;
       font-weight: bold;
       /* border: 1px solid black; */
  }
  .test-contain{
    margin-top: 60px;
    /* overflow-x: hidden; */
  }
 