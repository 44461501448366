.off-row{
    /* border: 1px solid black; */
}
.ad{
    margin-top: 20px;
    margin-bottom: 80px;
}
.off-col h3{
    font-size: 50px;
    font-weight: bold;
    color: rgb(231, 77, 21);
}
.off-col p{
    font-size: 18px;
    color: white;
}
.off-col{
    /* border: 2px solid red; */
    background-color: rgb(39, 39, 39);
    padding: 20px;
    border-radius: 5px;
}
.off-col1{
    /* border: 1px solid red; */
}
.p-off{
    margin-left: 0;
}
.bikeimg{
    width: 100%;
    height: 100%;
    margin-left: 0;
}
@media screen  and (max-width:500px){
    .bikeimg{
        width:93%;
        height: 92%;
    }
}