@import url("https://fonts.googleapis.com/css2?family=Rosario&display=swap");

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 70px;
}

.gif-sec {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.head {
  padding-left: 80px;
  padding-right: 80px;
  
}
.sub-head {
  display: flex;
  align-items: center;
}
.title h1 {
  font-size:40px;
  font-weight: bold;
}
.one {
  font-size: 24px;
  color: orangered;
}
.two {
  font-size: 24px;
  color: rgb(82, 162, 193);
}

p {
  font-size: 16px;
  text-align: justify;
  margin-top: 10px;
}
.tool {
  font-size: 25px;
}
.engine {
  height: 350px;
  width: 50%;
  margin-top: 40px;
  /* padding: 50px; */
  /* padding-top: 0px; */
}

.main1 {
  display: flex;
  flex-direction: column;
  gap: 23px;
  /* border: 1px solid black; */
}
.main2 {
  display: flex;
  flex-direction: column;
  gap: 23px;
  /* border: 1px solid black; */

}

.last { 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.last p {
  width: 400px;
  text-align: justify;
}
.s-btn{
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.service-btn{
  color: white;
  background-color: black;
  padding: 10px 20px;
}
@media (max-width: 1024px) {
  .one {
    font-size: 17px;
    color: orangered;
  }
  p{
    margin-top: 4px;
    font-size: 18px;
  }
  .two {
    font-size: 13px;
    color: rgb(82, 162, 193);
  }
  .main1, .main2  {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23px;
  }
  .head{
    padding-left: 35px;
    padding-right: 35px;
  }

  p {
    font-size: 12px;
    text-align: justify;

  }
  .engine {
    padding: 0;
 }
}
/*Tablet */

@media only screen and  (min-width:480px) and (max-width: 769px) {
    .sub-head {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }
    p{
      margin-top: 4px;
      font-size: 15px;
    }
    .head {
      padding: 10px;
      padding-left: 30px;
    padding-right: 30px;
    }
    .two{
      font-size: 17px;
    }
  
    .main1, .main2  {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 25px;
    }
  }



  /* mobile */

@media only screen and (max-width: 480px) {
  .sub-head {
    display: flex;
    flex-direction: column;
  }
.two{
  font-size: 17px;
}
  .head {
    padding-left: 20px;
    padding-right: 20px;
  }
  .engine{
    width:200px ;
    height:200px ;
    margin-top: 10px  ;
  }
  .main1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .main2{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .last{
    display: flex;
    align-items: start;
    margin-top: 10px;
  }
  .sub-head{
    margin-top: 30px;
  }
  p{
    margin-top: 4px;
  }
  .last p{
    width: 390px;
    margin: 0 auto;
  }
  .last-head{
    margin-left: 20px;
  }
}
@media screen and (max-width:376px) {
  .last p{
    width: 320px;
    margin: 0 auto;
  }
  .last-head{
    margin-left: 25px;
  }
}
@media screen and (max-width:321px) {
  .last p{
    width: 275px;
    margin: 0 auto;
  }
  .last-head{
    margin-left: 25px;
  }
}

