.getin-contain{
    margin-top: 100px;
    /* border: 1px solid black; */
}
.get-head{
    font-size: 60px;
    font-weight: bold;
}
.get-para{
    font-size: 39px;
    margin-top: 10px;
    font-weight: 700;
}
.get-btn{
    margin-top: 10px;
    padding: 10px 25px;
    /* color: white; */
    background-color: #CD3E0A;
    font-size: 20px;
    border-radius: 5px;
    border: none;
    transition: 0.3s;

    /* box-shadow: 2px 2px 10px #1a1919; */
}
.get-btn a:hover{
    /* background-color: white; */
    /* border: #CD3E0A; */
    transition: 0.3s;

    color: #CD3E0A;
}
.get-btn:hover{
    transition:ease-in;
    background-color: #fff;
    border: 1px solid #CD3E0A;
    transition: 0.3s;

}
.get-btn a{
    text-decoration: none;
    color: white;
}
.span-getin{
    color: #13acd3;
}
.getin-img{
    width: 40%;
    margin-left: 20px;
}
@media screen and (max-width:800px){
    .get-head{
        text-align:center;
        font-size: 40px;
    }
    .get-para{
        text-align: center;

    }
    .get-btn{
        margin-left: 50px;
    }
    .getintouch-col2{
        margin-left: 80px;
    }
    .getin-img{
        width: 30%;
        margin-left: 20px;
    }
}
@media screen and (max-width:500px){
    .get-head{
        text-align:center;
        margin-bottom: 30px;
    }
    .get-para{
        text-align: center;
        font-size: 30px;
    }
    .get-btn{
        margin-top: 0;
        margin-left: 0;
        text-align: center;
    }
    .getin-img{
        display: none;
    }
    .getintouch-col2{
        margin-left: 0;
    }
    .map{
        width: 100%;
        height: 300px;
        margin-top: 20px;
    }
    .getin-col1 {
       
        display: flex;
        flex-direction: column;
        align-items: center;
       
    }
}
